exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-esferas-y-dirigibles-tsx": () => import("./../../../src/pages/esferas-y-dirigibles.tsx" /* webpackChunkName: "component---src-pages-esferas-y-dirigibles-tsx" */),
  "component---src-pages-figuras-rc-tsx": () => import("./../../../src/pages/figuras-rc.tsx" /* webpackChunkName: "component---src-pages-figuras-rc-tsx" */),
  "component---src-pages-globos-tsx": () => import("./../../../src/pages/globos.tsx" /* webpackChunkName: "component---src-pages-globos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inflables-voladores-tsx": () => import("./../../../src/pages/inflables-voladores.tsx" /* webpackChunkName: "component---src-pages-inflables-voladores-tsx" */),
  "component---src-pages-nosotros-tsx": () => import("./../../../src/pages/nosotros.tsx" /* webpackChunkName: "component---src-pages-nosotros-tsx" */),
  "component---src-pages-promociones-tsx": () => import("./../../../src/pages/promociones.tsx" /* webpackChunkName: "component---src-pages-promociones-tsx" */)
}

